import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    footer: {
        position: 'fixed',
        bottom: 0,
        color: '#dfdfdf',
        fontSize: '0.5rem',
        marginLeft: 5,
        marginBottom:5
    }
}));

export default function BottomAppBar() {
    const classes = useStyles();

    return (
        <Typography className={classes.footer}>&copy; 2020 dashcarsales.com</Typography>
    );
}
