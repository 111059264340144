import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography/Typography";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import Collapse from "@material-ui/core/Collapse/Collapse";
import TuneIcon from '@material-ui/icons/Tune';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => {

    theme.overrides = {

    };
    return {
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            position: 'absolute',
            right: 10,
            top: 12,
            cursor: 'pointer',
            border: '1px solid #fff',
            padding: '1px',
            borderRadius: 4,
            height: 24,
        },
        title: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: 1.5,
            fontSize: '.9rem',
            letterSpacing: '0.0075em',
            fontWeight: 500,
            marginTop: -15,
                [theme.breakpoints.up('sm')]: {
                fontSize: '1.3rem',
            },
            ['@media (max-width:335px)']: {
                fontSize: '0.8rem',
            },
            cursor: 'pointer',
        },
        appBar: {
            boxShadow: 'none'
        },
        dlButton: {
            position: 'absolute',
            right: 45,
            top: 5,
            minWidth: '30',
            width: 30,
            transform: 'rotate(180deg)'
        },
        toolbar: {
            position: 'relative',
            alignItems: 'normal',
            minHeight: 50,
            paddingLeft: 0,
            paddingRight: 0,
        },
        toolbarMain: {
            marginLeft: 10,
            marginRight: 10,
        },
        dashcarsales: {
            position: 'absolute',
            top: 14,
            left: 0,
        },
        menuItem: {
            padding: '10px 0px',
            borderBottom: '1px dashed #000',
        },
        menuItemLink: {
            paddingLeft: 10,
            fonxtSize: '0.9rem',
            color: 'blue',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        },

        topMenu: {
            marginLeft: 275,
            marginTop: 13,
            '@media print' : {
                display: 'none'
            }
        },
        topMenuHasColumn: {
            marginLeft: '265px',
        },
        topMenuMain: {
            marginLeft: 200,
        },
        topMenuItem: {
            fontSize: '0.9rem',
            color: '#fff',
            marginLeft: 30,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        },
        accountCont: {
            position: 'absolute',
            right: 30,
            fontSize: '0.9rem',
            marginTop: 13,
            '@media print' : {
                display: 'none !important'
            }
        },
        accountIcon: {
            position: 'absolute',
            right: 0,
            marginTop: 12,
            marginLeft: 10,
            color: '#fff',
        },
        accountName: {
            fontSize: '0.9rem',
            color: '#fff',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        },


    }
});

export default function AppBarSearch(props) {
    const classes = useStyles();

    const [search, setSearch] = React.useState();
    const [expanded, setExpanded] = React.useState();

    const isMobile = props.isMobile;

    const showTopMenu = !isMobile && props.size !== 'l' && !((props.size === 'xl' || props.size === 'xxl') && props.user && props.user.type > 1);

    const handleMenuButtonClick = () => {
        setExpanded(!expanded);
    };

    const handleSearchClick = (e) => {
        e.preventDefault();
    }

    const handleDl = () => {
        window.location = '/manager/download-search?q='+search;
    };

    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={clsx(classes.toolbar, classes.toolbarMain)}>
                    <a href="/"><img src="/i/assets/dash-carsales.png" height={props.isMobile ? 20 : 20}
                                     title="DASH CARSALES" alt="DASH CARSALES" className={classes.dashcarsales}/></a>
                    {showTopMenu ?
                        <>
                            <div className={clsx(classes.topMenu, props.hasColumn ? classes.topMenuHasColumn : classes.topMenuMain)}
                            style={{

                            }}
                            >
                                <Typography>
                                    <a className={classes.topMenuItem} href="/used-cars">Used Cars</a>
                                    <a className={classes.topMenuItem} href="/membership">Membership</a>
                                    <a className={classes.topMenuItem} href="/sell-your-car">Sell Your Car</a>
                                    <a className={classes.topMenuItem} href="/sign-up">Dealer Sign-Up</a>

                                    {props.user && props.user.type === 2 ?
                                        <>
                                            <a className={classes.topMenuItem} href="/reservations">Reservations</a>
                                            <a className={classes.topMenuItem} href="/inventory">Inventory</a>
                                        </> : ''}
                                    {props.user && props.user.isAdmin ?
                                        <>
                                            <a className={classes.topMenuItem} href="/manager/leads">Leads</a>
                                            <a className={classes.topMenuItem} href="/manager/calls">Calls</a>
                                        </> : ''}
                                    {props.user && props.user.isCallAgent ?
                                        <>
                                            <a className={classes.topMenuItem} href="/manager/calls">Calls</a>
                                        </> : ''}
                                </Typography>
                            </div>
                            <div className={classes.accountCont}>
                                <Typography>
                                    <a href="/member/account" className={classes.accountName}>
                                        {props.user && props.user.username ? props.user.username : 'My account'}
                                     </a>
                                </Typography>
                            </div>
                            <a href="/member/account">
                                <AccountCircleOutlinedIcon className={classes.accountIcon}/>
                            </a>
                        </>
                    : null}

                    {0 && !showTopMenu ?
                        <div className={classes.search} onClick={handleSearchClick}>
                            <TuneIcon className={classes.tuneIcon}/>
                            <Typography className={classes.searchText}>Search</Typography>
                        </div> : null
                    }

                    {!showTopMenu ?
                        <div className={classes.menuButton} onClick={handleMenuButtonClick}>
                            <MenuOutlinedIcon/>
                        </div> : null
                    }
                </Toolbar>
            </AppBar>
            {!showTopMenu ?
                <Collapse in={expanded} timeout={100} unmountOnExit>
                    <div className={classes.menuItem} onClick={() => window.location='/used-cars'}>
                        <Typography><a className={classes.menuItemLink} href="/used-cars">Used Cars</a></Typography>
                    </div>
                    <div className={classes.menuItem} onClick={() => window.location='/membership'}>
                        <Typography><a className={classes.menuItemLink} href="/membership">Membership</a></Typography>
                    </div>
                    <div className={classes.menuItem} onClick={() => window.location='/sell-your-car'}>
                        <Typography><a className={classes.menuItemLink} href="/sell-your-car">Sell Your Car</a></Typography>
                    </div>
                    <div className={classes.menuItem} onClick={() => window.location='/sign-up'}>
                        <Typography><a className={classes.menuItemLink} href="/sign-up">Dealer Sign-Up</a></Typography>
                    </div>
                    {props.user && props.user.type === 2 ?
                        <>
                            <div className={classes.menuItem} onClick={() => window.location='/reservations'}>
                                <Typography><a className={classes.menuItemLink} href="/reservations">Reservations</a></Typography>
                            </div>
                            <div className={classes.menuItem} onClick={() => window.location='/sign-up'}>
                                <Typography><a className={classes.menuItemLink} href="/inventory">Inventory</a></Typography>
                            </div>
                        </> : ''}
                    {props.user && props.user.isAdmin ?
                        <>
                            <div className={classes.menuItem} onClick={() => window.location='/manager/leads'}>
                                <Typography><a className={classes.menuItemLink} href="/manager/leads">Leads</a></Typography>
                            </div>
                            <div className={classes.menuItem} onClick={() => window.location='/manager/calls'}>
                                <Typography><a className={classes.menuItemLink} href="/manager/calls">Calls</a></Typography>
                            </div>
                        </> : ''}

                    {props.user && props.user.isCallAgent ?
                            <div className={classes.menuItem} onClick={() => window.location='/manager/calls'}>
                                <Typography><a className={classes.menuItemLink} href="/manager/calls">Calls</a></Typography>
                            </div>
                         : ''}

                    <div className={classes.menuItem} onClick={() => window.location='/member/account'}>
                        <Typography><a className={classes.menuItemLink} href="/member/account">
                            My Account{props.user && props.user.username ? ' ('+props.user.username+')' : ''}
                            </a></Typography>
                    </div>
                </Collapse> : null}
        </div>
    );
}
