import React, {useState, useEffect, Suspense} from 'react';
import './App.css';
import Container from "@material-ui/core/Container/Container";
import AppBarSearch from "./AppBar";
import {useHistory } from 'react-router-dom'
import BottomAppBar from "./BottomAppBar";
import {makeStyles} from "@material-ui/core/styles/index";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client/index";
import {managerUrl, updateUrl} from "./config";
import { ApolloProvider } from '@apollo/client';
import User from "./User";
import * as ReactGA from "react-ga";
import {CircularProgress} from "@material-ui/core/index";
import {mainTrackingId} from './config';
import Responsive from "./Components/Responsive/Responsive";
import clsx from 'clsx';
import useFilter from "./Components/Filter/UseFilter";
import Footer from "./Components/Layout/Footer";

//import HeadAd from "./Components/Ads/HeadAd";

const useStyles1 = makeStyles({
    rootMobile: {
        width: '100%'
    },
    cont: {
        wminWidth: '100%'
    },
    contl: {
        paddingLeft:0,
        paddingRight:0,
        wminWidth: 700
    },
    contxl: {
        paddingLeft:0,
        paddingRight:0,
        wminWidth: 900
    },
    contxxl: {
        paddingLeft:0,
        paddingRight:0,
        wminWidth: 1070,
        wwidth: '100%',
    },
    contItemxxl: {
        paddingLeft:0,
        paddingRight:0,
        minWidth: 1000
    },
    contxxxl: {
        paddingLeft:0,
        paddingRight:0,
        maxWidth: 1450
    },
    contItemxxxl: {
        paddingLeft:0,
        paddingRight:0,
        maxWidth: 1280
    },
    wrapperLeft: {
        minHeight: 0,
        display: 'flex',
        flexFlow: 'row wrap'
    },
    progress: {
        marginLeft: '50%',
        marginTop: 200
    },
    bar: {
        position: 'absolute',
        width: '100%',
        height: 50,
        backgroundColor: '#3f51b4'
    },
});

const link = createHttpLink({
    uri: updateUrl,
    credentials: 'same-origin'
});


const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
});

function App(props) {

    const useStyles = makeStyles({
        root: {
            width: props.page === 'main' ? '100%' : '99%',
            paddingLeft: 0,
            paddingRight: 0
        },
    }, { name: 'MuiContainer' });

    const history = useHistory();
    const [currentSn, setCurrentSn] = useState();
    const [currentRequestCode, setCurrentRequestCode] = useState();
    const [search, setSearch] = useState('');
    const [reFetchFavorites, setReFetchFavorites] = useState(false);
    const [reFetchItem, setReFetchItem] = useState(false);
    const [user, setUser] = useState(null);
    const [loggedIn, setLoggedIn] = useState();
    const [returnRoute, setReturnRoute] = useState();
    const [returnToItemRoute, setReturnToItemRoute] = useState();

    let sn = currentSn ? currentSn : props.match.params.sn;
    let code = currentRequestCode ? currentRequestCode : sn ? null : props.match.params.code;
    let about = props.about;
    let terms = props.terms;
    let privacy = props.privacy;
    let dealerSignUp = props.dealerSignUp;
    let update = props.update;
    let reservations = props.reservations;
    let inventory = props.inventory;
    let dealer = props.dealer;
    let lp = props.lp;
    let list = props.list;
    let reviews = props.reviews;
    let blog = props.blog;

    let maxWidth = '100%';

    const getSize = (width) => {
        if (width >= 1200) {
            maxWidth = lp ? 1000 : sn || code ? 1280 : 1450;
            return 'xxxl';
        } else if (width >= 1070) {
            maxWidth = lp || sn || code ? 1000 : 1080;
            return 'xxl';
        } else if (width >= 930) {
            maxWidth = 900;
            return 'xl';
        } else if (width > 700) {
            maxWidth = 700;
            return 'l';
        } else if (width > 400) {
            return 'mxxx';
        } else {
            return 'mxx';
        }
    };

    const [size, setSize] = useState(getSize(window.innerWidth));

    useStyles();
    const classes = useStyles1();

    const Item = React.lazy(() => import('./Item'));
    const CarListPage = React.lazy(() => import('./Components/CarListPage/CarListPage'));
    const Favorites = React.lazy(() => import('./Favorites'));
    const About = React.lazy(() => import('./About'));
    const DealerSignUp = React.lazy(() => import('./Components/DealerSignUp/DealerSignUp'));
    const Update = React.lazy(() => import('./Update'));
    const Reservations = React.lazy(() => import('./Dealer/Reservations'));
    const Inventory = React.lazy(() => import('./Dealer/Inventory'));
    const Dealer = React.lazy(() => import('./Dealer'));
    const LandingPage = React.lazy(() => import('./LandingPage'));
    const Reviews = React.lazy(() => import('./Components/Reviews/Reviews'));
    const Blog = React.lazy(() => import('./Components/Blog/Blog'));
    const DashTabs = React.lazy(() => import('./Components/Dash/DashTabs'));
    const MainPage = React.lazy(() => import('./Components/MainPage/MainPage'));
    const SignUp = React.lazy(() => import('./Components/Membership/SignUp/SignUp'));
    const LogIn = React.lazy(() => import('./Components/Membership/LogIn/LogIn'));
    const Remind = React.lazy(() => import('./Components/Membership/Remind/Remind'));
    const Account = React.lazy(() => import('./Components/Membership/Account/Account'));
    const Logout = React.lazy(() => import('./Components/Membership/Logout/Logout'));
    const UpdateInformation = React.lazy(() => import('./Components/Membership/UpdateInformation/UpdateInformation'));
    const RemindConfirm = React.lazy(() => import('./Components/Membership/RemindConfirm/RemindConfirm'));
    const ChangePassword = React.lazy(() => import('./Components/Membership/ChangePassword/ChangePassword'));
    const UpgradeMembership = React.lazy(() => import('./Components/Membership/UpgradeMembership/UpgradeMembership'));
    const SellYourCar = React.lazy(() => import('./Components/SellYourCar/SellYourCar'));
    const Help = React.lazy(() => import('./Components/Help/Help'));
    const ContactUs = React.lazy(() => import('./Components/ContactUs/ContactUs'));
    const Settings = React.lazy(() => import('./Components/Settings/Settings'));
    const LeadsPage = React.lazy(() => import('./Components/Leads/LeadsPage'));
    const CallCenterPage = React.lazy(() => import('./Components/CallCenter/CallCenterPage'));
    const DealerLogIn = React.lazy(() => import('./DealerLogin'));

    const hostname = window.location.hostname;
    console.log('hostname', hostname)
    if (hostname.indexOf('127.0.0.1') === -1 && hostname.indexOf('local') === -1) {
        ReactGA.initialize(mainTrackingId);
    }

    const handleClick = (item, returnRoute = null) => {
        //if (returnRoute === null) {
        //  throw new Error('null route')
        //}
        console.log('returnRoute', returnRoute, 'sn', item.sn);
        if (item.requestCode) {
            setCurrentRequestCode(item.requestCode);
            setCurrentSn(null);
        } else {
            setCurrentRequestCode(null);
            setCurrentSn(item.sn);
            window.scrollTo(0, 0);
        }
        setReturnRoute(returnRoute);
    };

    let timeout = null;
    const onSearchChange = (value) => {
        //clearTimeout(timeout);
        if (value !== undefined && (value.length >= 3 || value.length === 0)) {
            //timeout = setTimeout(() => {
            if (search !== value) {
                setSearch(value);
                history.push('/');
            }
            //}, 1000)
        }
    };

    const onFavoritesChanged = () => {
        setReFetchFavorites(true);
    }

    const onFavoritesReFetch = () => {
        setReFetchFavorites(false);
    }

    const onItemChanged = () => {
        setReFetchItem(true);
    }

    const onItemReFetch = () => {
        setReFetchItem(false);
    }

    const onUserLoaded = user => {
        setUser(user);
    }

    const onLoggedIn = () => {
        setLoggedIn(true);
    }

    const onResize = width => {
        const s = getSize(width);
        console.log('s', s, width);
        if (size !== s) {
            setSize(s);
        }
    };

    useEffect(() => {
        history.listen(() => {
            setCurrentSn(null);
            setCurrentRequestCode(null);
        });
    });

    const screenWidth = Math.min(document.body.clientWidth, window.innerWidth, window.screen.width);

    console.log('screenWidth', screenWidth);
    const isMobile = screenWidth < 700 || size.substr(0,1) === 'm';

    const filter = useFilter();

    let classPostfix = '';
    if ((size === 'xxxl' || size === 'xxl')) {
        if (code || sn) {
            classPostfix = 'Item';
        }
    }

    const hasColumn = props.page === 'list' || sn || code || dealer;

    return (
        <Suspense fallback={<CircularProgress className={classes.progress}/>}>
            {isMobile ? null : <Responsive onResize={onResize}/>}
            <User onUserLoaded={onUserLoaded} loggedIn={loggedIn}/>
                <div className={classes.bar}> </div>
            <AppBarSearch user={user} isMobile={isMobile} size={size} hasColumn={hasColumn}/>
            <Container className={clsx(classes.cont, isMobile ? classes.rootMobile : null,
                    classes['cont'+classPostfix+size])}
                style={props.page === 'main' ? {maxWidth: 'none'} : null}
                >
                    <div className={classes.wrapperLeft} id="mainWrapper">
                        {/*<HeadAd isMobile={isMobile} sn={currentSn} request={currentRequestCode} search={search} location={window.location.pathname}/>*/}
                        {code ? <Item code={code} onFavoritesChanged={onFavoritesChanged} reFetchItem={reFetchItem}
                                      onItemReFetch={onItemReFetch}
                                      handleClick={handleClick}
                                      setReturnToItemRoute={setReturnToItemRoute}
                                      size={size}
                                      isMobile={isMobile}
                                      screenWidth={screenWidth}
                                      filter={filter}
                            /> :
                            (update ? <ApolloProvider client={client}><Update sn={sn} onItemChanged={onItemChanged}/></ApolloProvider> :
                                    (sn ? <Item sn={sn} onFavoritesChanged={onFavoritesChanged} reFetchItem={reFetchItem}
                                                onItemReFetch={onItemReFetch} setCurrentRequestCode={setCurrentRequestCode}
                                                returnRoute={returnRoute}
                                                handleClick={handleClick}
                                                setReturnToItemRoute={setReturnToItemRoute}
                                                size={size}
                                                isMobile={isMobile}
                                                screenWidth={screenWidth}
                                                filter={filter}
                                                dashTabs={(p) => <ApolloProvider client={client}><DashTabs {...p}/></ApolloProvider>}
                                            /> :
                                            (props.favorites ? <Favorites handleClick={handleClick} reFetchFavorites={reFetchFavorites}
                                                                          onFavoritesReFetch={onFavoritesReFetch}/> :
                                                    (about ? <About isMobile={isMobile}/> :
                                                            (dealerSignUp ? <DealerSignUp onLoggedIn={onLoggedIn} isMobile={isMobile}/> :
                                                                    (inventory ? <ApolloProvider client={client}><Inventory handleClick={handleClick} isMobile={isMobile} size={size}/></ApolloProvider> :
                                                                            (reservations ? <ApolloProvider client={client}><Reservations isMobile={isMobile}/></ApolloProvider> :
                                                                                    (dealer ? <Dealer handleClick={handleClick} size={size} isMobile={isMobile} filter={filter}/> :
                                                                                            (lp ? <LandingPage handleClick={handleClick} size={size}  isMobile={isMobile}
                                                                                                               setReturnToItemRoute={setReturnToItemRoute} maxWidth={maxWidth}/> :
                                                                                                    (reviews ? <Reviews handleClick={handleClick}
                                                                                                                        returnToItemRoute={returnToItemRoute}
                                                                                                                        setReturnToItemRoute={setReturnToItemRoute}
                                                                                                                        isMobile={isMobile}/> :
                                                                                                            blog ? <Blog handleClick={handleClick}
                                                                                                                         returnToItemRoute={returnToItemRoute}
                                                                                                                         setReturnToItemRoute={setReturnToItemRoute}
                                                                                                                         isMobile={isMobile}/> :
props.page === 'membership-sign-up' ? <SignUp handleClick={handleClick}
size={size}  isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'membership-login' ? <LogIn handleClick={handleClick}
size={size}  isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'membership-remind' ? <Remind handleClick={handleClick}
size={size}  isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'membership-remind-confirm' ? <RemindConfirm match={props.match} size={size} isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'membership-change-password' ? <ChangePassword size={size} isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'membership-account' ? <Account handleClick={handleClick}  size={size}  isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'membership-upgrade-membership' ? <UpgradeMembership size={size}  isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'membership-logout' ? <Logout size={size}  isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'membership-update-information' ? <UpdateInformation size={size} isMobile={isMobile} maxWidth={maxWidth} user={user}/> :
props.page === 'sell-your-car' ? <SellYourCar isMobile={isMobile} user={user}/> :
props.page === 'help' ? <Help isMobile={isMobile} user={user}/> :
props.page === 'contact-us' ? <ContactUs isMobile={isMobile} user={user}/> :
props.page === 'settings' ? <Settings isMobile={isMobile} user={user}/> :
props.page === 'manager-leads' ? <LeadsPage isMobile={isMobile} user={user}/> :
props.page === 'manager-calls' ? <CallCenterPage isMobile={isMobile} user={user}/> :
props.page === 'dealer-log-in' ? <DealerLogIn isMobile={isMobile} user={user}/> :



                                                                                                                    list ? <CarListPage handleClick={handleClick} size={size}  isMobile={isMobile} user={user}
                                                                                                                                setReturnToItemRoute={setReturnToItemRoute} filter={filter} screenWidth={screenWidth}/> :
                                                                                                                <MainPage handleClick={handleClick} user={user}
                                                                                                                      setReturnToItemRoute={setReturnToItemRoute}
                                                                                                                      size={size} isMobile={isMobile} filter={filter}
                                                                                                                        match={props.match} maxWidth={maxWidth}
                                                                                                                          screenWidth={screenWidth}
                                                                                                                />
                                                                                                    )
                                                                                            )
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            )
                                    )
                            )
                        }
                    </div>
                </Container>
            <Footer isMobile={isMobile} maxWidth={maxWidth} hasColumn={hasColumn} is1000={!hasColumn}
                    isItem={sn || code}
                    screenWidth={screenWidth}/>
        </Suspense>
    );
}

export default App;
