import { useState, useEffect } from 'react';
import * as ReactGA from "react-ga";

let useFilter__params = {
    defaultsSet: null,
    total: null,
    page: 1,
    limit: 12,
    priceRangeMinOriginal: null,
    priceRangeMaxOriginal: null,
    priceRangeMin: null,
    priceRangeMax: null,
    maxPrice: null,
    minPrice: null,
    hasPriceFilter: false,
    priceRangeLimitMin: 200000,
    priceRangeLimitMax: 5100000,
    priceRangeValue: [],
    make: null,
    selectedMakes: {},
    minYearLimit: 1981,
    maxYearLimit: (new Date).getFullYear(),
    minYear: null,
    maxYear: null,
    filterOptions: global.eParams && global.eParams.filterOptions ? JSON.parse(global.eParams.filterOptions) :
        localStorage.getItem('sorting') ? [{name: 's', value: localStorage.getItem('sorting')}] : [],
    searchIncrement: 0,
    isLoadingResults: false,
    mobileFilterExpanded: false,
    distance: 50,
    distanceLimit: 501,
    zipCode: null,
    location: null,
    options: null,
    sorting: localStorage.getItem('sorting') ? localStorage.getItem('sorting') : null,
    sold: localStorage.getItem('showSold') === '1' ? '1' : null,
    makesArray: [],
    modelsArray: [],
    trimArray: [],
    hasSelection: null,
};

function useFilter(data) {

    const params = useFilter__params;

    const callbacks = {};

    const addCallback = (type, fn) => {
        callbacks[type] = fn;
    };

    const dispatch = () => {
        for (let i in callbacks) {
            callbacks[i](params);
        }
    };

    const resetPriceRangeCallbacks = [];

    const addResetPriceRangeCallback = (fn) => {
        resetPriceRangeCallbacks.push(fn);
    };

    const dispatchResetPriceRange = () => {
        params.hasPriceFilter = false;
        resetPriceRangeCallbacks.map(callback => {
            callback(params);
        });
    };

    const resetMakeCallbacks = {};

    const addResetMakeCallback = (type, fn) => {
        resetMakeCallbacks[type] = fn;
    };

    const dispatchResetMake = () => {
        for (let i in resetMakeCallbacks) {
            resetMakeCallbacks[i](params);
        }
    };

    const dispatchCallbacks = {};

    const addDispatchCallback = (type, fn) => {
        dispatchCallbacks[type] = fn;
    };

    const dispatchFilter = (resetPage = true) => {
        if (resetPage) {
            params.page = 1;
        }
        params.isLoadingResults = true;
        params.hasPriceFilter = false;
        params.hasSelection = false;
        const newFilterOptions = [];
        const makesArray = [];
        const modelsArray = [];
        const trimArray = [];

        if (params.maxPrice !== params.priceRangeMax || params.minPrice !== params.priceRangeMin) {
            if (params.maxPrice) {
                newFilterOptions.push({
                    name: 'maxPrice',
                    value: params.maxPrice >= params.priceRangeLimitMax
                        ? params.priceRangeMaxOriginal.toString() : params.maxPrice.toString()})
                params.hasSelection = true;
            }
            if (params.minPrice) {
                newFilterOptions.push({name: 'minPrice', value: params.minPrice.toString()});
                params.hasSelection = true;
            }
            params.hasPriceFilter = true;
        }

        for (let i in params.selectedMakes) {
            let hasModels = false;
            if (params.selectedMakes[i]['models'] && params.selectedMakes[i]['models']) {
                for (let j in params.selectedMakes[i]['models']) {
                    if (params.selectedMakes[i]['models'][j]['trim']) {
                        for (let k in params.selectedMakes[i]['models'][j]['trim']) {
                            newFilterOptions.push({name: 'trim', value: i+';;'+j+';;'+k});
                            makesArray.push(i);
                            modelsArray.push(j);
                            trimArray.push(k);
                            params.hasSelection = true;
                        }
                    } else {
                        newFilterOptions.push({name: 'model', value: i+';;'+j});
                        makesArray.push(i);
                        modelsArray.push(j);
                        params.hasSelection = true;
                    }
                    hasModels = true;
                }
            }
            if (!hasModels) {
                newFilterOptions.push({name: 'make', value: i});
                makesArray.push(i);
                params.hasSelection = true;
            }
        }

        params.makesArray = makesArray;
        params.modelsArray = modelsArray;
        params.trimArray = trimArray;

        if (params.minYear && params.minYear !== params.minYearLimit) {
            newFilterOptions.push({name: 'minYear', value: params.minYear.toString()});
            params.hasSelection = true;
        }
        if (params.maxYear && params.maxYear !== params.maxYearLimit) {
            newFilterOptions.push({name: 'maxYear', value: params.maxYear.toString()});
            params.hasSelection = true;
        }

        if (params.distance && params.distance !== params.distanceLimit && params.zipCode) {
            newFilterOptions.push({name: 'distance', value: params.distance.toString()});
            newFilterOptions.push({name: 'zipCode', value: params.zipCode.toString()});
            params.hasSelection = true;
        }

        if (params.location) {
            newFilterOptions.push({name: 'location', value: params.location});
        }

        if (params.options) {
            newFilterOptions.push({name: 'options', value: params.options.toString()});
            params.hasSelection = true;
        }

        if (params.sorting) {
            newFilterOptions.push({name: 's', value: params.sorting.toString()});
        }

        if (params.sold) {
            newFilterOptions.push({name: 'sold', value: params.sold.toString()});
        }

        newFilterOptions.push({name: 'p', value: params.page.toString()});
        newFilterOptions.push({name: 'l', value: params.limit.toString()});

        params.filterOptions = newFilterOptions;
        params.searchIncrement++;

        for (let i in dispatchCallbacks) {
            dispatchCallbacks[i](params.filterOptions);
        };

        ReactGA.event({
            category: 'User',
            action: 'search_filter',
            label: JSON.stringify(params.filterOptions)
        });
    };

    const setLandingPageFilterOptions = (data) => {
        console.log('setLandingPageFilterOptions', data)
        if (params.defaultsSet) {
            return;
        }
        if (data) {
            const parsed = typeof data === 'string' || data instanceof String ? JSON.parse(data) : data;

            const selectedMakes = {};

            parsed.map(option => {
                switch (option.name) {
                    case 'maxPrice':
                        if (!params.maxPrice) {
                            params.maxPrice = parseInt(option.value);
                            params.priceRangeValue[1] = params.maxPrice;
                            params.hasPriceFilter = true;
                            params.hasSelection = true;
                        }
                        break;
                    case 'minPrice':
                        if (!params.minPrice) {
                            params.minPrice = parseInt(option.value);
                            params.priceRangeValue[0] = params.minPrice;
                            params.hasPriceFilter = true;
                            params.hasSelection = true;
                        }
                        break;
                    case 'make':
                        selectedMakes[option.value] = {'models':{}};
                        params.hasSelection = true;
                        break;
                    case 'model':
                        const parts = option.value.split(';;');
                        if (!selectedMakes[parts[0]]) {
                            selectedMakes[parts[0]] = {'models':{}};
                        }
                        selectedMakes[parts[0]]['models'][parts[1]] = {};
                        params.hasSelection = true;
                        break;
                    case 'trim':
                        const parts2 = option.value.split(';;');
                        if (!selectedMakes[parts2[0]]) {
                            selectedMakes[parts2[0]] = {'models':{}};
                        }
                        if (!selectedMakes[parts2[0]]['models'][parts2[1]]) {
                            selectedMakes[parts2[0]]['models'][parts2[1]] = {'trim':{}};
                        }
                        selectedMakes[parts2[0]]['models'][parts2[1]]['trim'][parts2[2]] = true;
                        params.hasSelection = true;
                        break;
                    case 'zipCode':
                        params.zipCode = option.value;
                        params.hasSelection = true;
                        break;
                    case 'location':
                        params.location = option.value;
                        break;
                    case 'maxYear':
                        params.maxYear = parseInt(option.value);
                        params.hasSelection = true;
                        break;
                    case 'minYear':
                        params.minYear = parseInt(option.value);
                        params.hasSelection = true;
                        break;
                    case 'options':
                        params.options = option.value;
                        params.hasSelection = true;
                        break;
                    case 'p':
                        params.page = +option.value;
                        break;
                    case 'l':
                        params.limit = +option.value;
                        break;
                    case 's':
                        params.sorting = option.value;
                        break;
                    case 'sold':
                        params.sold = option.value;
                        break;
                }
            });

            console.log('set', params);
            params.selectedMakes = selectedMakes;

        }

        params.defaultsSet = true;

    };

    const setPriceRange = (min, max) => {
        params.priceRangeMinOriginal = min;
        params.priceRangeMaxOriginal = max;
        min = Math.max(params.priceRangeLimitMin, min);
        max = Math.min(params.priceRangeLimitMax, max);
        if (params.priceRangeMin !== min) {
            params.priceRangeMin = min;
        }
        if (params.priceRangeMax !== max) {
            params.priceRangeMax = max;
        }
        if (params.priceRangeMin > params.priceRangeLimitMax) {
            params.priceRangeMin = params.priceRangeLimitMin;
            params.hasPriceFilter = false;
        }

        if (!params.priceRangeMax) {
            params.priceRangeMax = params.priceRangeLimitMax;
            if (params.priceRangeMin === params.priceRangeMax) {
                params.priceRangeMin = params.priceRangeLimitMin;
            }
            params.hasPriceFilter = false;
        }
        params.isLoadingResults = false;
    };

    const setMinMaxPrice = (min, max) => {
        if (params.minPrice !== min) {
            params.minPrice = min;
        }
        if (params.maxPrice !== max) {
            params.maxPrice = max;
        }
    };

    const setTotal = (value) => {
        if (params.total !== value) {
            params.total = value;
        }
    };

    if (params.filterOptions && params.filterOptions.length) {
        setLandingPageFilterOptions(params.filterOptions);
    }

    return {params, setLandingPageFilterOptions, setPriceRange, setTotal, addCallback, dispatch,
        setMinMaxPrice, dispatchFilter, addResetPriceRangeCallback, dispatchResetPriceRange, addDispatchCallback,

    };
}

export default useFilter;
