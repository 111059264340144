import React, { useEffect } from 'react';

export default function Responsive(props) {
    let timeout = null;
    const updateWindowWidth = () => {
        //console.log('updateWindowWidth');
        dispatch();
    };

    useEffect(() => {
        window.removeEventListener('resize', updateWindowWidth);
        window.addEventListener('resize', updateWindowWidth);
        dispatch();
    });

    const dispatch = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (props.onChange) {
                props.onChange(window.innerWidth < 700);
            }
            if (props.onResize) {
                props.onResize(window.innerWidth);
            }
        }, 100);
    };

    return (
        <></>
    );
}