import * as React from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

const GET_USER = gql`
  query {
        getUser {
            type
            username
            isManager
            isUser
            isSEO
            isAdmin
            isCallAgent
        }
  }
`;

export default function(props) {
    const {loading, error, data, refetch} = useQuery(GET_USER);

    if (data) {
        props.onUserLoaded(data.getUser);
    }

    if (data && props.loggedIn) {
        refetch();
    }

    return null;
}